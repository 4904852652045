import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Chip,
  CircularProgress,
  Alert,
  Stack,
  IconButton,
  Tooltip,
  Divider,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  ArrowBack,
  AccessTime,
  Assignment,
  LocationOn,
  Person,
  Receipt,
  Search,
  CheckCircle,
  PendingActions,
  Cancel,
  PictureAsPdf,
  FileDownload,
  Preview as PreviewIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import SearchCertificate from "./SearchCertificate";
import { pdf } from "@react-pdf/renderer";

const SearchDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [parcel, setParcel] = useState(null);
  const [ownership, setOwnership] = useState(null);
  const [billing, setBilling] = useState(null);
  const [valuation, setValuation] = useState(null);

  const fetchSearchDetails = async () => {
    setLoading(true);
    try {
      const searchRes = await fetch(`/api/searches/${id}`);
      const searchData = await searchRes.json();
      setSearch(searchData);

      // Fetch parcel details
      const parcelRes = await fetch(
        `/api/landparcels?plotNo=${searchData.plotNumber}&includeAdminUnits=true`
      );
      const parcelData = await parcelRes.json();
      setParcel(parcelData);

      // Fetch ownership details
      const ownershipRes = await fetch(
        `/api/ownerships?plotNo=${searchData.plotNumber}`
      );
      const ownershipData = await ownershipRes.json();
      setOwnership(ownershipData);

      // Fetch billing details
      const billingRes = await fetch(
        `/api/billings?plotNo=${searchData.plotNumber}`
      );
      const billingData = await billingRes.json();
      setBilling(billingData);

      // Fetch valuation details
      const valuationRes = await fetch(
        `/api/valuations?plotNo=${searchData.plotNumber}`
      );
      const valuationData = await valuationRes.json();
      setValuation(valuationData);
    } catch (error) {
      console.error("Error fetching details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSearchDetails();
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case "Completed":
        return <CheckCircle color="success" />;
      case "Ongoing":
        return <PendingActions color="warning" />;
      default:
        return <Cancel color="error" />;
    }
  };

  return (
    <Box sx={{ bgcolor: "#F5F7FA", minHeight: "100vh", pt: "64px" }}>
      {/* Header Section */}
      <Paper
        elevation={0}
        sx={{
          bgcolor: "white",
          borderBottom: "1px solid #E0E0E0",
          position: "sticky",
          top: 64,
          zIndex: 10,
        }}
      >
        <Box sx={{ maxWidth: 1200, mx: "auto", px: 3, py: 2 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => navigate("/search")}
                sx={{
                  bgcolor: "rgba(0,0,0,0.04)",
                  "&:hover": { bgcolor: "rgba(0,0,0,0.08)" },
                }}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" fontWeight={500}>
                Search Application Details
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center" mt={0.5}>
                <Typography variant="body2" color="text.secondary">
                  #{search?.reference}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AccessTime fontSize="small" color="action" />
                  <Typography variant="body2" color="text.secondary">
                    {dayjs(search?.createdAt).format("MMM D, YYYY h:mm A")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center" spacing={1}>
                {getStatusIcon(search?.status)}
                <Typography
                  variant="subtitle1"
                  color={
                    search?.status === "Completed"
                      ? "success.main"
                      : search?.status === "Ongoing"
                      ? "warning.main"
                      : "error.main"
                  }
                  fontWeight={500}
                >
                  {search?.status}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Main Content */}
      <Box sx={{ maxWidth: 1200, mx: "auto", px: 3, py: 4 }}>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              {/* Search Information */}
              <Paper elevation={0} sx={{ p: 3, bgcolor: "white" }}>
                <Stack direction="row" alignItems="center" spacing={2} mb={3}>
                  <Avatar sx={{ bgcolor: "primary.light" }}>
                    <Search />
                  </Avatar>
                  <Typography variant="h6">Search Information</Typography>
                </Stack>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Plot Number
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {search?.plotNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Purpose of Search
                    </Typography>
                    <Typography variant="body1">
                      {search?.purposeOfSearch}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Search Scope
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {search?.searchScope?.property && (
                        <Chip
                          label="Property Section"
                          size="small"
                          sx={{
                            bgcolor: "primary.soft",
                            color: "primary.dark",
                            fontWeight: 500,
                          }}
                        />
                      )}
                      {search?.searchScope?.proprietorship && (
                        <Chip
                          label="Proprietorship"
                          size="small"
                          sx={{
                            bgcolor: "primary.soft",
                            color: "primary.dark",
                            fontWeight: 500,
                          }}
                        />
                      )}
                      {search?.searchScope?.encumbrance && (
                        <Chip
                          label="Encumbrance"
                          size="small"
                          sx={{
                            bgcolor: "primary.soft",
                            color: "primary.dark",
                            fontWeight: 500,
                          }}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              {/* Documents Row - Payment and Certificate side by side */}
              <Grid container spacing={3}>
                {/* Payment Evidence */}
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{ p: 3, bgcolor: "white", height: "100%" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      mb={3}
                    >
                      <Avatar sx={{ bgcolor: "success.light" }}>
                        <Receipt />
                      </Avatar>
                      <Typography variant="h6">Payment Evidence</Typography>
                    </Stack>

                    {search?.paymentEvidence ? (
                      imageError ? (
                        <Alert severity="error">
                          Failed to load payment evidence
                        </Alert>
                      ) : (
                        <Box
                          sx={{
                            border: "1px solid #e0e0e0",
                            borderRadius: 1,
                            bgcolor: "#f8f9fa",
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                            height: "calc(100% - 60px)",
                          }}
                        >
                          <Receipt
                            sx={{
                              fontSize: 48,
                              color: "success.main",
                              mb: 1,
                            }}
                          />
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<FileDownload />}
                            onClick={() => {
                              const paymentUrl = `/api/uploads/payments/${search.paymentEvidence}`;
                              window.open(paymentUrl, "_blank");
                            }}
                            sx={{
                              borderRadius: 2,
                              textTransform: "none",
                              boxShadow: "none",
                              "&:hover": {
                                boxShadow: "none",
                                bgcolor: "success.dark",
                              },
                            }}
                          >
                            View Payment Evidence
                          </Button>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            align="center"
                            sx={{ maxWidth: 300 }}
                          >
                            Click to view or download the payment receipt
                          </Typography>
                        </Box>
                      )
                    ) : (
                      <Alert
                        severity="info"
                        sx={{
                          "& .MuiAlert-message": { width: "100%" },
                        }}
                      >
                        No payment evidence uploaded
                      </Alert>
                    )}
                  </Paper>
                </Grid>

                {/* Search Certificate */}
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{ p: 3, bgcolor: "white", height: "100%" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      mb={3}
                    >
                      <Avatar sx={{ bgcolor: "error.light" }}>
                        <PictureAsPdf />
                      </Avatar>
                      <Typography variant="h6">Search Certificate</Typography>
                    </Stack>

                    <Box
                      sx={{
                        border: "1px solid #e0e0e0",
                        borderRadius: 1,
                        bgcolor: "#f8f9fa",
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      {search?.status === "Completed" ? (
                        search?.searchCertificate ? (
                          <>
                            <PictureAsPdf
                              sx={{
                                fontSize: 48,
                                color: "error.main",
                                mb: 1,
                              }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<FileDownload />}
                              onClick={() => {
                                const certificateUrl = `/api/uploads/certificates/${search.searchCertificate}`;
                                window.open(certificateUrl, "_blank");
                              }}
                              sx={{
                                borderRadius: 2,
                                textTransform: "none",
                                boxShadow: "none",
                                "&:hover": {
                                  boxShadow: "none",
                                  bgcolor: "primary.dark",
                                },
                              }}
                            >
                              View Certificate
                            </Button>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              align="center"
                              sx={{ maxWidth: 300 }}
                            >
                              Click to view or download the official search
                              certificate
                            </Typography>
                          </>
                        ) : (
                          <Alert severity="info" sx={{ width: "100%" }}>
                            Certificate is being processed. Please check back
                            later.
                          </Alert>
                        )
                      ) : (
                        <Alert severity="info" sx={{ width: "100%" }}>
                          Certificate will be available once the search is
                          completed
                        </Alert>
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{ p: 3, bgcolor: "white" }}>
              <Stack direction="row" alignItems="center" spacing={2} mb={3}>
                <Avatar sx={{ bgcolor: "info.light" }}>
                  <Person />
                </Avatar>
                <Typography variant="h6">Applicant Details</Typography>
              </Stack>
              <Stack spacing={3}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Full Name
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {search?.applicantName}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Phone Number
                  </Typography>
                  <Typography variant="body1">
                    {search?.applicantPhone}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    ID Number
                  </Typography>
                  <Typography variant="body1">{search?.idNumber}</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Add the Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ height: "80vh", p: 0 }}>
          <PDFViewer width="100%" height="100%" style={{ border: "none" }}>
            <SearchCertificate
              search={search}
              parcel={parcel}
              ownership={ownership}
              valuation={valuation}
              billing={billing}
            />
          </PDFViewer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchDetails;
