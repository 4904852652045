import React from "react";
import {
  Box,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Email,
  Phone,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Grid2";
import Logo from "../../assets/imgs/lg.png";
import oslLogo from "../../assets/imgs/Oakar-Logo-new.png";
import hfhLogo from "../../assets/imgs/hfh-logo.png";
import hfhblack from "../../assets/imgs/hfh-black-logo.png";

const contactItems = [
  {
    icon: <Facebook sx={{ mr: 1 }} />,
    label: "Facebook",
    link: "https://www.facebook.com/people/County-Government-Of-Homa-Bay/100095138725427/",
  },
  {
    icon: <Instagram sx={{ mr: 1 }} />,
    label: "Instagram",
    link: "https://www.instagram.com/county_goverment_of_homabay/?igsh=dWlyd2c3bWc5bjM2",
  },
  {
    icon: <YouTube sx={{ mr: 1 }} />,
    label: "YouTube",
    link: "https://www.youtube.com/channel/UCFY_ha43Pjao-JLeQJPSTpg",
  },
  {
    icon: <Twitter sx={{ mr: 1 }} />,
    label: "Twitter",
    link: "https://x.com/HomaBayCountyKE",
  },
  {
    icon: <Email sx={{ mr: 1 }} />,
    label: "governor@homabay.go.ke",
    link: "mailto:governor@homabay.go.ke",
  },
  {
    icon: <Phone sx={{ mr: 1 }} />,
    label: "0800 000 870",
    link: null,
  },
];

export default function FooterSection() {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        color: "#fff",
        fontWeight: "800",
        py: 4,
      }}
    >
      <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        <Grid2 container spacing={{ xs: 4, md: 6 }} justifyContent="center">
          {/* Logo and Name */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                flexDirection: { xs: "column", sm: "row" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              <img
                src={Logo}
                alt="Homabay LIMS Public Portal"
                style={{
                  width: "80px",
                  height: "80px",
                  marginRight: { xs: 0, sm: 10 },
                  marginBottom: { xs: 2, sm: 0 },
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 800,
                  color: "#E06126",
                  mt: { xs: 1, sm: 0 },
                }}
              >
                Homabay LIMS Public Portal
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                maxWidth: { xs: "100%", sm: 300 },
                textAlign: { xs: "center", sm: "justify" },
                lineHeight: 1.5,
              }}
            >
              Empowering secure land tenure and sustainable resource management.
            </Typography>
          </Grid2>

          {/* Quick Links */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E06126" }}>
              Legal Information
            </Typography>
            <List>
              {[
                { label: "Terms & Conditions", path: "/terms" },
                { label: "Privacy Policy", path: "/privacy" },
              ].map((item, index) => (
                <ListItem key={index} disablePadding>
                  <Link
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "#fff",
                      textDecoration: "none",
                      "&:hover": { color: "#E06126" },
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid2>

          {/* Our Partners */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E06126" }}>
              Our Partners
            </Typography>
            <List>
              {[
                { name: "Homa-Bay County", url: "https://www.homabay.go.ke/" },
                { name: "Ministry of Lands", url: "https://lands.go.ke" },
                {
                  name: "Survey of Kenya",
                  url: "https://www.un-spider.org/survey-kenya-sok",
                },
              ].map((partner, index) => (
                <ListItem key={index} disablePadding>
                  <Link
                    href={partner.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "#fff",
                      textDecoration: "none",
                      "&:hover": {
                        color: "#E06126",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <ListItemText primary={partner.name} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid2>

          {/* Contact and Social Media */}
          <Grid2 item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E06126" }}>
              Contact Us
            </Typography>
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              {contactItems.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                    justifyContent: { xs: "center", sm: "flex-start" },
                    width: "auto",
                  }}
                >
                  {item.icon}
                  {item.link ? (
                    <Link
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        "&:hover": { color: "#E06126" },
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {item.label}
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid2>
        </Grid2>

        {/* Partner Logos Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-start" },
            alignItems: "center",
            mt: 3,
            gap: { xs: 2, sm: 4 },
            pl: { xs: 0, sm: 13 },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <img
            src={oslLogo}
            alt="Oakar Services Limited"
            style={{
              height: "auto",
              width: "200px",
              maxWidth: "100%",
            }}
          />
          <img
            src={hfhblack}
            alt="Habitat for Humanity"
            style={{
              height: "auto",
              width: "150px",
              maxWidth: "100%",
            }}
          />
        </Box>

        {/* Copyright */}
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            mt: 4,
            px: 2,
          }}
        >
          &copy; {new Date().getFullYear()} Homabay LIMS Public Portal. All
          rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}
