import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Tabs,
  Tab,
  Chip,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { Add as AddIcon, Visibility, Upload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { jwtDecode } from "jwt-decode";

// Initialize dayjs plugins
dayjs.extend(relativeTime);

const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    return dayjs(dateString).format("MMM D, YYYY h:mm A");
  } catch (error) {
    console.error("Invalid date:", dateString);
    return "";
  }
};

const formatElapsedTime = (dateString) => {
  if (!dateString) return "";
  try {
    return dayjs(dateString).fromNow();
  } catch (error) {
    console.error("Invalid date:", dateString);
    return "";
  }
};

const PaymentDialog = ({ open, onClose, onSubmit }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Payment Information</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Search Fee: KSh 1,000
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            Please upload a photo/scan of your payment receipt
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <input
            accept="image/*,application/pdf"
            style={{ display: "none" }}
            id="payment-evidence"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="payment-evidence">
            <Button
              variant="outlined"
              component="span"
              startIcon={<Upload />}
              fullWidth
            >
              Upload Payment Evidence
            </Button>
          </label>
        </Box>

        {preview && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <img
              src={preview}
              alt="Payment preview"
              style={{ maxWidth: "100%", maxHeight: 200 }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSubmit(file)}
          variant="contained"
          disabled={!file}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ParcelSearch = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], total: 0 });
  const [applicantId, setApplicantId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // Determine page size based on screen size
  const getDefaultPageSize = () => {
    if (isMobile) return 5;
    if (isTablet) return 8;
    return 10;
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: getDefaultPageSize(),
    page: 0,
  });

  // Update pagination when screen size changes
  useEffect(() => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: getDefaultPageSize(),
    }));
  }, [isMobile, isTablet]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const navigate = useNavigate();

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null);

  const [counts, setCounts] = useState({
    completed: 0,
    ongoing: 0,
    rejected: 0,
  });

  const tabs = [
    { label: "Completed", count: counts.completed },
    { label: "Ongoing", count: counts.ongoing },
    { label: "Rejected", count: counts.rejected },
  ];

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  useEffect(() => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        navigate("/login");
        return;
      }

      const decodedToken = jwtDecode(token);
      setApplicantId(decodedToken.id);
    } catch (error) {
      console.error("Token decode error:", error);
      navigate("/login");
    }
  }, [navigate]);

  const fetchSearches = async () => {
    if (!applicantId) return;

    setLoading(true);
    try {
      const status = tabs[activeTab].label;
      const response = await fetch(
        `/api/searches?applicantId=${applicantId}&status=${status}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch searches");
      }

      const result = await response.json();

      setData({
        data: result.data,
        total: result.total,
      });

      // Update counts from the response
      setCounts(result.counts);
    } catch (error) {
      console.error("Error fetching searches:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (applicantId) {
      fetchSearches();
    }
  }, [activeTab, applicantId]);

  // Add showSnackbar helper function
  const showSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handlePaymentSubmit = async (file) => {
    try {
      const formData = new FormData();
      formData.append("paymentEvidence", file);

      const response = await fetch(
        `/api/searches/${selectedSearch.id}/payment-evidence`,
        {
          method: "PATCH",
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to upload payment evidence");

      setPaymentDialogOpen(false);
      fetchSearches(); // Refresh the list
      showSnackbar("Payment evidence uploaded successfully");
    } catch (error) {
      console.error("Payment upload error:", error);
      showSnackbar("Failed to upload payment evidence", "error");
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      flex: 0.5,
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    {
      field: "reference",
      headerName: "Reference number",
      flex: 1,
    },
    {
      field: "plotNumber",
      headerName: "Plot No",
      flex: 0.5,
    },
    {
      field: "purposeOfSearch",
      headerName: "Purpose of Search",
      flex: 1,
    },
    {
      field: "applicantPhone",
      headerName: "Phone Number",
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Date of submission",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) return "";
        try {
          return dayjs(params.value).format("MMM D, YYYY h:mm A");
        } catch (error) {
          console.error("Date formatting error:", error);
          return "";
        }
      },
    },
    {
      field: "elapsedTime",
      headerName: "Elapsed time",
      flex: 0.8,
      renderCell: (params) => {
        if (!params?.row?.createdAt) return "";
        try {
          return dayjs(params.row.createdAt).fromNow();
        } catch (error) {
          console.error("Elapsed time calculation error:", error);
          return "";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          label={params?.value || "Unknown"}
          color={
            params?.value === "Completed"
              ? "success"
              : params?.value === "Ongoing"
              ? "warning"
              : "error"
          }
          size="small"
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => navigate(`/searches/${params.id}`)}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ margin: "3rem 1rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
          My Plot Search Applications
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/search/new")}
        >
          New Application
        </Button>
      </Box>

      <Card sx={{ p: 2, position: "relative", height: "auto", minHeight: 400 }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  textTransform: "capitalize",
                  minWidth: isMobile ? "auto" : 120,
                }}
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {tab.label}
                    <Chip
                      label={tab.count}
                      size="small"
                      sx={{ backgroundColor: "#f0f0f0" }}
                    />
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>

        <DataGrid
          rows={data.data}
          columns={columns.map((col) => ({
            ...col,
            width: isMobile
              ? col.width
                ? col.width * 0.8
                : undefined
              : col.width,
            flex: isMobile ? undefined : col.flex,
          }))}
          rowCount={data.total}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={(newModel) => {
            setPaginationModel(newModel);
          }}
          pageSizeOptions={
            isMobile ? [5, 10] : isTablet ? [8, 15, 25] : [10, 20, 50, 100]
          }
          loading={loading}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: !isMobile,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          autoHeight
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: getDefaultPageSize(),
              },
            },
          }}
          sx={{
            border: "none",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row": {
              fontSize: isMobile ? "0.875rem" : undefined,
            },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: isMobile ? "0.875rem" : undefined,
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "1px solid rgba(224, 224, 224, 1)",
            },
          }}
        />
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <PaymentDialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        onSubmit={handlePaymentSubmit}
      />
    </Box>
  );
};

export default ParcelSearch;
