import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Chip,
  CircularProgress,
  Pagination,
  Grid2,
  Container,
  Paper,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";

export default function Payments(props) {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [expandedPaymentId, setExpandedPaymentId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [enquiries, setEnquiries] = useState([]);
  const [count, setCount] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [selectPayment, SetSelectPayment] = useState(null);
  const [resolutionText, setResolutionText] = useState("");
  const [resolutionDialogOpen, setResolutionDialogOpen] = useState(false);
  const limit = 10;

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No authentication token found");
        }

        const decodedToken = jwtDecode(token);
        setUserDetails({
          id: decodedToken.id,
          name: decodedToken.name,
          phone: decodedToken.phoneNumber,
          email: decodedToken.email,
        });

        fetchPayments();
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    if (userDetails?.id) {
      fetchPayments();
    }
  }, [page, rowsPerPage, column, searchValue, refresh, userDetails?.id]);

  const fetchPayments = () => {
    if (!userDetails?.id) return;

    setLoading(true);
    const url = `/api/billings?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}${
      searchValue ? `&${column}=${searchValue}` : ""
    }&ownershipId=${userDetails.id}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Error fetching billings");
        }
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        setEnquiries(data?.data || []);
        setCount(data?.total || 0);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPage(0);
  };

  const handleStatusUpdate = () => {
    setUpdating(true);
    fetch(`/api/billings/${selectPayment.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: true,
        resolution: resolutionText,
        userId: props.user.id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setEnquiries((prevEnquiries) =>
            prevEnquiries.map((enq) =>
              enq.ID === selectPayment.ID
                ? {
                    ...enq,
                    status: true,
                    resolution: resolutionText,
                    userId: props.user.id,
                  }
                : enq
            )
          );
          setResolutionDialogOpen(false);
          SetSelectPayment(null);
        } else throw new Error("Error updating enquiry status");
      })
      .catch((e) => console.error("Failed to update enquiry status:", e))
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleRowClick = (enquiry) => {
    SetSelectPayment(enquiry);
  };

  const handleExpandClick = (id) => {
    setExpandedPaymentId(expandedPaymentId === id ? null : id);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return (
      <Box sx={{ display: "grid", placeItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={2} sx={{ p: 2, margin: "3rem 1rem" }}>
      <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
        Payments
      </Typography>
      <Stack spacing={2}>
        {enquiries.length > 0 ? (
          enquiries.map((payment) => (
            <Card
              key={payment.id}
              sx={{
                position: "relative",
                boxShadow: "0px 4px 16px #60606040",
                borderRadius: "8px",
              }}
            >
              <CardContent sx={{ mb: 0, pb: 0 }}>
                <Typography
                  sx={{ position: "absolute", top: "1.5em", right: "1em" }}
                  variant="body2"
                  color="text.secondary"
                >
                  {new Date(payment?.createdAt).toLocaleString()}
                </Typography>

                <Typography variant="h6" color="primary" mb={1}>
                  KSh {payment?.billingAmount}
                </Typography>

                <Chip
                  label={`Billed on ${new Date(
                    payment.dateBilled
                  ).toLocaleDateString()}`}
                  sx={{ position: "absolute", bottom: "1.5em", right: "1em" }}
                />
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => handleExpandClick(payment.id)}
                >
                  {expandedPaymentId === payment.id ? (
                    <ArrowForwardIos
                      sx={{ rotate: "-90deg", fontSize: "small", mr: 1 }}
                    />
                  ) : (
                    <ArrowForwardIos
                      sx={{ rotate: "90deg", fontSize: "small", mr: 1 }}
                    />
                  )}{" "}
                  {expandedPaymentId === payment.id
                    ? "Hide Details"
                    : "View Details"}
                </Button>
              </CardContent>
              <Collapse
                in={expandedPaymentId === payment.id}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  <Divider />
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Billed Account Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>National ID:</strong>{" "}
                      {payment.nationalId}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Phone Number:</strong>{" "}
                      {payment.phoneNumber}
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>
                      Rate Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Ground Rent:</strong>{" "}
                      KSh {(payment.landRent - 0).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Land Rates:</strong>{" "}
                      KSh {(payment.landRate - 0).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Arrears:</strong> KSh{" "}
                      {(payment.arrears - 0).toLocaleString()}
                    </Typography>
                  </Grid2>
                </CardContent>
              </Collapse>
            </Card>
          ))
        ) : (
          <Typography>No payments found.</Typography>
        )}
      </Stack>
      <Pagination
        count={Math.ceil(enquiries.length / limit) || 1}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
      />
    </Paper>
  );
}
