import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Divider,
  Box,
  Stack,
  CardContent,
  Collapse,
  Chip,
  Grid2,
  Paper,
  Container,
  CircularProgress,
  Alert,
} from "@mui/material";
import SingleParcel from "../components/ParcelSearch/SingleParcel";
import { ArrowForwardIos } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";

export default function Plots() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedLoanId, setExpandedLoanId] = useState(null);
  const [error, setError] = useState(null);
  const [ownershipId, setOwnershipId] = useState(null);
  const [user, setUser] = useState(null);

  // Decode token and fetch data
  useEffect(() => {
    const initialize = async () => {
      try {
        // First decode token
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No authentication token found");
        }

        const decodedToken = jwtDecode(token);
        console.log("Decoded token:", decodedToken);

        if (!decodedToken.id) {
          throw new Error("No ID found in token");
        }

        setOwnershipId(decodedToken?.id);

        // Finally fetch plots
        const plotsResponse = await fetch(
          `/api/parcellink?ownershipId=${decodedToken?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!plotsResponse.ok) {
          throw new Error("Failed to fetch plots");
        }

        const plotsData = await plotsResponse.json();
        setData(plotsData);

        if (plotsData?.data?.length > 0) {
          setExpandedLoanId(plotsData.data[0].id);
        }
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []); // Only run once on mount

  const handleExpandClick = (loanId) => {
    setExpandedLoanId((prevId) => (prevId === loanId ? null : loanId));
  };

  return (
    <Paper elevation={2} sx={{ p: 2, margin: "3rem 1rem" }}>
      <Box>
        <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
          My Plots
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        ) : data?.data?.length === 0 ? (
          <Alert severity="info" sx={{ mt: 2 }}>
            No plots found
          </Alert>
        ) : (
          <Stack spacing={2}>
            {data?.data?.map((link) => (
              <Card
                key={link.id}
                sx={{
                  position: "relative",
                  boxShadow: "0px 4px 16px #60606040",
                  borderRadius: "8px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Plot Number: {link.landparcels?.plotNo}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>Start Date:</strong>{" "}
                    {new Date(link.startDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ fontWeight: 500 }}>End Date:</strong>{" "}
                    {link.endDate
                      ? new Date(link.endDate).toLocaleDateString()
                      : "Ongoing"}
                  </Typography>
                  <Chip
                    label={link.isActive ? "Active" : "Inactive"}
                    sx={{ position: "absolute", top: "1em", right: "1em" }}
                    color={link.isActive ? "success" : "default"}
                  />
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    variant="outlined"
                    size="small"
                    onClick={() => handleExpandClick(link.id)}
                  >
                    {expandedLoanId === link.id ? (
                      <ArrowForwardIos
                        sx={{ rotate: "-90deg", fontSize: "small", mr: 1 }}
                      />
                    ) : (
                      <ArrowForwardIos
                        sx={{ rotate: "90deg", fontSize: "small", mr: 1 }}
                      />
                    )}{" "}
                    {expandedLoanId === link.id
                      ? "Hide Details"
                      : "View Details"}
                  </Button>
                </CardContent>
                <Collapse
                  in={expandedLoanId === link.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    {/* Land Parcel Details Section */}
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Land Parcel Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Grid2 container spacing={3}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <Typography variant="body2" gutterBottom>
                          <strong style={{ fontWeight: 500 }}>
                            Plot Number:
                          </strong>{" "}
                          {link.landparcels?.plotNo || "N/A"}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong style={{ fontWeight: 500 }}>
                            FR Number:
                          </strong>{" "}
                          {link.landparcels?.frNo || "N/A"}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong style={{ fontWeight: 500 }}>Area:</strong>{" "}
                          {link.landparcels?.area} hectares
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <SingleParcel
                          geom={link.landparcels?.geom}
                          area={link.landparcels?.area}
                        />
                      </Grid2>
                    </Grid2>
                    {/* Owner Details Section */}
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Owner Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Gender:</strong>{" "}
                      {link.owners?.gender}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>
                        Ownership Type:
                      </strong>{" "}
                      {link.owners?.ownershipType}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>County:</strong>{" "}
                      {link.owners?.county}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>
                        Physical Address:
                      </strong>{" "}
                      {link.owners?.physicalAddress}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Email:</strong>{" "}
                      {link.owners?.email}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </Stack>
        )}
      </Box>
    </Paper>
  );
}
