import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";

export default function EnquiryDialog({ open, onClose, refresh, setRefresh, userDetails }) {
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    category: "",
    name: "",
    phone: "",
    ownershipId: ""
  });

  // Auto-fill user details when dialog opens or userDetails changes
  useEffect(() => {
    if (userDetails) {
      setFormData(prev => ({
        ...prev,
        name: userDetails.name || "",
        phone: userDetails.phone || "",
        ownershipId: userDetails.id || ""
      }));
    }
  }, [userDetails, open]);

  const handleCategoryChange = (event) => {
    setFormData({
      ...formData,
      category: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form:", formData);
    
    try {
      const token = localStorage.getItem("authToken");
      const payload = {
        ...formData,
        ownershipId: userDetails?.id
      };
      console.log("Sending payload:", payload);

      const response = await fetch("/api/enquiries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      // Log the raw response for debugging
      const responseText = await response.text();
      console.log("Raw response:", responseText);

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.message || errorData.error || "Failed to create support ticket";
        } catch {
          errorMessage = responseText || "Failed to create support ticket";
        }
        throw new Error(errorMessage);
      }

      // Only try to parse as JSON if we haven't already consumed the body
      const result = responseText ? JSON.parse(responseText) : {};
      console.log("Success:", result);

      setRefresh(!refresh);
      onClose();
      // Reset form but keep user details
      setFormData({
        title: "",
        message: "",
        category: "",
        name: userDetails?.name || "",
        phone: userDetails?.phone || "",
        ownershipId: userDetails?.id || ""
      });
    } catch (error) {
      console.error("Error creating support ticket:", error);
      alert(`Failed to create support ticket: ${error.message}`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Support Ticket</DialogTitle>
      <DialogContent>
        <Box 
          component="form" 
          id="enquiry-form" 
          onSubmit={handleSubmit} 
          sx={{ mt: 2 }}
          noValidate // Add this to prevent browser validation
        >
          {/* Auto-filled fields (read-only) */}
          <TextField
            fullWidth
            label="Name"
            value={formData.name}
            disabled
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Phone"
            value={formData.phone}
            disabled
            sx={{ mb: 2 }}
          />

          {/* User input fields */}
          <TextField
            fullWidth
            label="Title"
            required
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            sx={{ mb: 2 }}
          />

          <FormControl fullWidth required sx={{ mb: 2 }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              value={formData.category}
              label="Category"
              onChange={(e) => {
                console.log("Category selected:", e.target.value); // Debug log
                setFormData({
                  ...formData,
                  category: e.target.value
                });
              }}
            >
              <MenuItem value="Technical">Technical Issue</MenuItem>
              <MenuItem value="Account">Account Related</MenuItem>
              <MenuItem value="Payment">Payment Issue</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            required
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            sx={{ mb: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          variant="contained" 
          type="submit"
          form="enquiry-form"
          onClick={(e) => {
            console.log("Submit button clicked"); // Debug log
            const form = document.getElementById('enquiry-form');
            form.dispatchEvent(new Event('submit', { cancelable: true }));
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
