import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Stack,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Alert,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const passwordRef = useRef(null);
  const [userData, setUserData] = useState({
    name: "",
    phoneNumber: "",
    altPhoneNumber: "",
    nationalId: "",
    email: "",
    county: "",
    subCounty: "",
    ward: "",
    physicalAddress: "",
    postalAddress: "",
  });
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [dloading, setDLoading] = useState(false);
  const [ploading, setPLoading] = useState(false);
  const [usr, setUsr] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false,
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Update the decodeAndSetUserDetails function
  const decodeAndSetUserDetails = () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const decodedToken = jwtDecode(token);
      console.log("Decoded token:", decodedToken);

      // Match the field names from the token (verify2FA function)
      setUserData({
        name: decodedToken.name || "",
        phoneNumber: decodedToken.phoneNumber || "", // Match token field
        altPhoneNumber: decodedToken.altPhoneNumber || "", // Match token field
        nationalId: decodedToken.nationalId || "",
        email: decodedToken.email || "",
        county: decodedToken.county || "",
        subCounty: decodedToken.subCounty || "",
        ward: decodedToken.ward || "",
        physicalAddress: decodedToken.physicalAddress || "",
        postalAddress: decodedToken.postalAddress || "",
      });
    } catch (error) {
      console.error("Error decoding token:", error);
      setMessage("Failed to load user details");
      setSeverity("error");
    }
  };

  // Call the function immediately when component mounts
  useEffect(() => {
    decodeAndSetUserDetails();
  }, []); // Empty dependency array means it runs once on mount

  useEffect(() => {
    if (location.state?.scrollToPassword && passwordRef.current) {
      passwordRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const checkPasswordCriteria = (password) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  useEffect(() => {
    checkPasswordCriteria(newPassword);
  }, [newPassword]);

  // Update the fetchUserDetails function
  const fetchUserDetails = async (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const response = await fetch(
        `/api/ownerships/getowner/${decodedToken.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch updated user details");
      }

      const data = await response.json();
      // Match the field names from the backend
      setUserData({
        name: data.name || "",
        phoneNumber: data.phoneNumber || "", // Match backend field
        altPhoneNumber: data.altPhoneNumber || "", // Match backend field
        nationalId: data.nationalId || "",
        email: data.email || "",
        county: data.county || "",
        subCounty: data.subCounty || "",
        ward: data.ward || "",
        physicalAddress: data.physicalAddress || "",
        postalAddress: data.postalAddress || "",
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
      setMessage("Updated but failed to refresh details");
      setSeverity("warning");
    }
  };

  // Update user details handler
  const handleUserUpdate = async () => {
    setDLoading(true);
    setUsr(true);
    try {
      const token = localStorage.getItem("authToken");
      const decodedToken = jwtDecode(token);

      console.log(decodedToken);

      const updatePayload = {
        name: userData.name,
        phoneNumber: userData.phoneNumber,
        altPhoneNumber: userData.altPhoneNumber,
        nationalId: userData.nationalId,
        email: userData.email,
        county: userData.county,
        subCounty: userData.subCounty,
        ward: userData.ward,
        physicalAddress: userData.physicalAddress,
        postalAddress: userData.postalAddress,
      };
      console.log("Update payload:", updatePayload);

      const response = await fetch(`/api/ownerships/${decodedToken.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatePayload),
      });

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      const data = responseText ? JSON.parse(responseText) : {};
      console.log("Parsed response:", data);

      if (response.ok) {
        await fetchUserDetails(token);
        setMessage(data.message || "User details updated successfully.");
        setSeverity("success");
      } else {
        throw new Error(
          data.error || data.message || "Failed to update user details"
        );
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      setMessage(error.message || "Failed to update user details.");
      setSeverity("error");
    }
    setDLoading(false);
    setOpenConfirmDialog(false);
  };

  // Update password handler
  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    setUsr(false);
    setMessage(null);

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      setSeverity("error");
      return;
    }

    if (
      !passwordCriteria.digit ||
      !passwordCriteria.length ||
      !passwordCriteria.lowercase ||
      !passwordCriteria.special ||
      !passwordCriteria.uppercase
    ) {
      setMessage("Enter a strong password!");
      setSeverity("error");
      return;
    }

    setPLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`/api/ownerships/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("Password updated successfully. You will be logged out.");
        setSeverity("success");

        // Clear password fields
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");

        // Wait for 2 seconds to show success message
        setTimeout(() => {
          // Clear local storage
          localStorage.clear();
          // Navigate to login page
          navigate("/login");
        }, 2000);
      } else {
        throw new Error(data.message || "Failed to update password");
      }
    } catch (error) {
      console.error("Password update error:", error);
      setMessage(error.message || "Failed to update password");
      setSeverity("error");
    }
    setPLoading(false);
  };

  return (
    <Box sx={{ p: 2, mt: 6 }}>
      <Stack spacing={3}>
        <Typography variant="h4">Settings</Typography>

        {/* User Details Form */}
        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <CardHeader title="User Details" />
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Name</InputLabel>
                    <OutlinedInput
                      label="Name"
                      value={userData.name}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          name: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput
                      label="Email"
                      value={userData.email}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          email: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Phone</InputLabel>
                    <OutlinedInput
                      label="Phone"
                      value={userData.phoneNumber}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Alternative Phone</InputLabel>
                    <OutlinedInput
                      label="Alternative Phone"
                      value={userData.altPhoneNumber}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          altPhoneNumber: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>ID Number</InputLabel>
                    <OutlinedInput
                      label="ID Number"
                      value={userData.nationalId}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          nationalId: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>County</InputLabel>
                    <OutlinedInput
                      label="County"
                      value={userData.county}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          county: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Sub County</InputLabel>
                    <OutlinedInput
                      label="Sub County"
                      value={userData.subCounty}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          subCounty: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Ward</InputLabel>
                    <OutlinedInput
                      label="Ward"
                      value={userData.ward}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          ward: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Physical Address</InputLabel>
                    <OutlinedInput
                      label="Physical Address"
                      value={userData.physicalAddress}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          physicalAddress: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Postal Address</InputLabel>
                    <OutlinedInput
                      label="Postal Address"
                      value={userData.postalAddress}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          postalAddress: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid2>
              </Grid2>
              {usr && message && <Alert severity={severity}>{message}</Alert>}
            </Stack>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => setOpenConfirmDialog(true)}
              disabled={dloading}
            >
              {dloading ? "Submitting..." : "Update Details"}
            </Button>
          </CardActions>
        </Card>

        {/* Confirmation Dialog */}
        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
        >
          <DialogTitle>Confirm Update</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to update your details? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleUserUpdate}
              color="primary"
              variant="contained"
              disabled={dloading}
            >
              {dloading ? "Updating..." : "Confirm Update"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Password Update Form */}
        <form onSubmit={handlePasswordUpdate}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
            ref={passwordRef}
          >
            <CardHeader subheader="Update password" title="Password" />
            <Divider />
            <CardContent>
              <Stack spacing={3}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.length ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least 8 characters long" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.uppercase ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one uppercase letter" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.lowercase ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one lowercase letter" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.digit ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one digit" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.special ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one special character" />
                  </ListItem>
                </List>
                <FormControl fullWidth>
                  <InputLabel>Old Password</InputLabel>
                  <OutlinedInput
                    label="Old Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </FormControl>

                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel>New Password</InputLabel>
                      <OutlinedInput
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel>Confirm Password</InputLabel>
                      <OutlinedInput
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          if (e.target.value != newPassword) {
                            setMessage("Passwords do not match");
                            setSeverity("error");
                          } else setMessage("");
                        }}
                      />
                    </FormControl>
                  </Grid2>
                </Grid2>
                {!usr && message && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" disabled={ploading}>
                {ploading ? "Updating Password..." : "Update Password"}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
    </Box>
  );
}
