import React from "react";
import { Box, Typography, CardMedia, useTheme, Container } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import About from "../../assets/imgs/about.jpg";

const AboutSection = () => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f5f5f5" }}>
      <Container>
        <Grid2 container spacing={4} alignItems="center">
          {/* Image Section */}
          <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
            <CardMedia
              component="img"
              image={About} // Replace with your image URL
              alt="About Us"
              sx={{
                width: "100%",
                borderRadius: "8px",
                boxShadow: theme.shadows[3],
              }}
            />
          </Grid2>

          {/* About Text Section */}
          <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                marginBottom: "1rem",
                color: "#061e06",
              }}
            >
              About the Homabay LIMS Public Portal
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#555", lineHeight: 1.6, marginBottom: "1rem" }}
            >
              The Homabay LIMS Public Portal is dedicated to providing
              streamlined and transparent management of land resources, ensuring
              secure land tenure and equitable access for all. We facilitate the
              registration, allocation, and documentation of land parcels,
              promoting sustainable land use and responsible ownership
              practices.
            </Typography>
            <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
              Through efficient administration and modern digital solutions, we
              aim to safeguard land rights, reduce disputes, and support
              informed decision-making. Our commitment is to enhance the
              livelihoods of residents by fostering trust and accountability in
              land transactions, contributing to the overall development and
              prosperity of the community.
            </Typography>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default AboutSection;
