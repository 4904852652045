import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import {
  Eye as EyeIcon,
  EyeSlash as EyeSlashIcon,
} from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog as VerificationDialog,
  Typography,
} from "@mui/material";
import ForgotPassword from "../ForgotPassword";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";

const schema = zod.object({
  phone: zod
    .string()
    .min(1, { message: "Phone number is required" })
    .regex(/^\d{9,10}$/, { message: "Phone number must be 9 or 10 digits" }),
  password: zod.string().min(1, { message: "Password is required" }),
});

const defaultValues = { phone: "", password: "" };

export default function LoginPage({ open, handleClose }) {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [isError, setIsError] = useState("");
  const [showing, setShowing] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const resetEmailRef = useRef();
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [userId, setUserId] = useState(null);
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [verificationMessage, setVerificationMessage] = useState("");

  useEffect(() => {
    let interval;
    if (showVerification && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showVerification, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const onSubmit = async (data) => {
    setIsError("");
    setSeverity("error");
    setIsPending(true);

    try {
      const response = await fetch("/api/ownerships/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: data.phone,
          password: data.password,
        }),
      });

      const body = await response.json();
      setIsPending(false);

      if (response.ok && body.requireVerification) {
        setUserId(body.userId);
        setShowVerification(true);
        setTimer(300);
        setSeverity("info");
        setVerificationMessage(body.message);
      } else if (response.ok) {
        handleLoginSuccess(body);
      } else {
        handleLoginError(response.status, body);
      }
    } catch (error) {
      handleConnectionError(error);
    }
  };

  const handleVerificationSubmit = async () => {
    if (!verificationCode) {
      setIsError("Please enter verification code");
      return;
    }

    setIsPending(true);
    try {
      const response = await fetch("/api/ownerships/verify-2fa", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          code: verificationCode,
        }),
      });

      const body = await response.json();
      setIsPending(false);

      if (response.ok) {
        handleLoginSuccess(body);
      } else {
        setIsError(body.message);
      }
    } catch (error) {
      handleConnectionError(error);
    }
  };

  const handleReset = async () => {
    setIsError("");
    setSeverity("error");
    const email = resetEmailRef.current.value.toLowerCase().trim();

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setIsError("Please Enter a Valid Email Address!");
      return;
    }

    try {
      setResetLoading(true);
      const response = await fetch("/api/admin/forgot-password", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Email: email }),
      });

      const data = await response.json();

      if (response.ok) {
        setSeverity("success");
        setIsError(data.message);
        setShowing(false); // Close the reset password dialog
      } else {
        setSeverity("error");
        setIsError(data.message);
      }
    } catch (err) {
      setSeverity("error");
      setIsError("Oops! Something went wrong");
    } finally {
      setResetLoading(false);
    }
  };

  const handleLoginSuccess = (body) => {
    localStorage.setItem("authToken", body.token);
    localStorage.setItem("userId", body.public.id);
    localStorage.setItem("ownershipId", body.public.ownershipId);
    setIsError(body?.message ?? "Login successful");
    setTimeout(() => {
      navigate("/home");
    }, 500);
  };

  const handleLoginError = (status, body) => {
    switch (status) {
      case 404:
        setIsError(
          "Account not found. Please check your phone number or register."
        );
        break;
      case 401:
        if (body.type === "INVALID_PASSWORD") {
          setIsError("Incorrect password. Please try again.");
        } else if (body.type === "PASSWORD_MISSING") {
          setIsError(
            "Password is required. Please set up your password first."
          );
        } else if (body.type === "ACCOUNT_INACTIVE") {
          setIsError("Your account is not active. Please contact support.");
        } else {
          setIsError("Authentication failed. Please check your credentials.");
        }
        break;
      case 400:
        if (body.type === "VALIDATION_ERROR") {
          setIsError(body.message || "Please check your input details.");
        } else if (body.type === "EMAIL_MISSING") {
          setIsError(
            "Email verification required. Please update your email address."
          );
        } else {
          setIsError(body.message || "Invalid request. Please try again.");
        }
        break;
      case 500:
        if (body.type === "EMAIL_SEND_ERROR") {
          setSeverity("warning");
          setIsError(
            "Could not send verification email. Please try again or contact support."
          );
        } else {
          setIsError("Server error. Please try again later.");
        }
        break;
      default:
        setIsError(body?.message || "Login failed. Please try again.");
    }

    // Log the error for debugging
    if (process.env.NODE_ENV === "development") {
      console.debug("Login Error:", { status, body });
    }
  };

  const handleConnectionError = (error) => {
    console.error("Login connection error:", error);
    setIsPending(false);
    setSeverity("error");
    setIsError(
      "Unable to connect to the server. Please check your internet connection and try again."
    );
  };

  const handleResendVerification = async () => {
    if (timer > 0) return; // Prevent resend if timer is still running

    try {
      setIsPending(true);
      const response = await fetch("/api/ownerships/resend-verification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      const data = await response.json();
      setIsPending(false);

      if (response.ok) {
        setTimer(300); // Reset timer
        setSeverity("success");
        setIsError("Verification code resent successfully.");
        setVerificationMessage(data.message);
      } else {
        setSeverity("error");
        setIsError(data.message || "Failed to resend verification code.");
      }
    } catch (error) {
      setIsPending(false);
      setSeverity("error");
      setIsError("Failed to resend verification code. Please try again.");
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: "center", color: "#0033A0" }}>
          Sign in
        </DialogTitle>
        <DialogContent>
          <form style={{ marginTop: "10px" }} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.phone)} fullWidth>
                    <InputLabel>Phone number</InputLabel>
                    <OutlinedInput {...field} label="Phone number" />
                    {errors.phone && (
                      <FormHelperText>{errors.phone.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <FormControl error={Boolean(errors.password)} fullWidth>
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                      {...field}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        showPassword ? (
                          <EyeIcon
                            cursor="pointer"
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <EyeSlashIcon
                            cursor="pointer"
                            onClick={() => setShowPassword(true)}
                          />
                        )
                      }
                      label="Password"
                    />
                    {errors.password && (
                      <FormHelperText>{errors.password.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <div>
                <Link
                  component={RouterLink}
                  onClick={() => setShowing(true)}
                  variant="subtitle2"
                  sx={{ textAlign: "center", color: "#0033A0" }}
                >
                  Forgot password?
                </Link>
              </div>
              {isError && (
                <Alert
                  severity={severity}
                  sx={{
                    "& .MuiAlert-message": {
                      whiteSpace: "pre-line",
                      fontWeight: severity === "error" ? 500 : 400,
                    },
                  }}
                >
                  {isError}
                </Alert>
              )}
              <Button
                disabled={isPending}
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                startIcon={isPending ? <CircularProgress size={24} /> : null}
                fullWidth
              >
                {isPending ? "Signing in..." : "Sign in"}
              </Button>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            {/* Close Button */}
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <VerificationDialog
        open={showVerification}
        onClose={() => setShowVerification(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center", color: "#0033A0" }}>
          Verify Login
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography>
              Please enter the verification code sent to your email.
            </Typography>
            <Typography color="primary" variant="subtitle2">
              Time remaining: {formatTime(timer)}
            </Typography>
            <TextField
              label="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              required
            />
            {isError && (
              <Alert
                severity={severity}
                sx={{
                  "& .MuiAlert-message": {
                    whiteSpace: "pre-line",
                    fontWeight: severity === "error" ? 500 : 400,
                  },
                }}
              >
                {isError}
              </Alert>
            )}
            <Button
              onClick={handleVerificationSubmit}
              variant="contained"
              color="primary"
              disabled={isPending || timer === 0}
              fullWidth
            >
              {isPending ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Verify"
              )}
            </Button>
          </Stack>
        </DialogContent>
      </VerificationDialog>
    </>
  );
}
