import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Person as PersonIcon,
  AccountCircle as AccountCircleIcon,
  Lock as LockIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const LoadingScreen = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 1)",
      zIndex: 1300,
    }}
  >
    <CircularProgress />
  </Box>
);

export default function Header(props) {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAccountDialog, setShowAccountDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserDetails({
        name: decodedToken.name || "",
        email: decodedToken.email || "",
        phoneNumber: decodedToken.phoneNumber || "",
        nationalId: decodedToken.nationalId || "",
        county: decodedToken.county || "",
        subCounty: decodedToken.subCounty || "",
        ward: decodedToken.ward || "",
      });
    }
    setLoading(false);
  }, []);

  const logout = () => {
    try {
      // Clear all local storage
      localStorage.clear();

      // Show loading while redirecting
      setLoading(true);

      // Add a small delay to show loading state
      setTimeout(() => {
        // Use navigate instead of window.location.href
        navigate("/");
      }, 500);
    } catch (error) {
      console.error("Logout error:", error);
      // Still use navigate even if there's an error
      navigate("/");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditDetails = () => {
    handleClose();
    navigate("/settings");
  };

  const handleChangePassword = () => {
    handleClose();
    navigate("/settings", { state: { scrollToPassword: true } });
  };

  const handleLogoutClick = () => {
    handleClose(); // Close the menu
    setShowLogoutDialog(true);
  };

  const handleLogoutConfirm = () => {
    setShowLogoutDialog(false);
    logout();
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          color: "white",
          width: "100%",
        }}
      >
        <IconButton
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          edge="start"
          sx={{
            color: "white",
            marginRight: 5,
            ...(props.open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}></Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            {userDetails?.name}
          </Typography>
          <IconButton color="inherit" onClick={handleClick}>
            <ArrowDropDownIcon />
            <AccountCircleIcon />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setShowAccountDialog(true);
              handleClose();
            }}
          >
            <AccountCircleIcon sx={{ mr: 1 }} /> Account
          </MenuItem>
          <MenuItem onClick={handleEditDetails}>
            <PersonIcon sx={{ mr: 1 }} /> Edit Details
          </MenuItem>
          <MenuItem onClick={handleChangePassword}>
            <LockIcon sx={{ mr: 1 }} /> Change Password
          </MenuItem>
          <MenuItem onClick={handleLogoutClick} sx={{ color: "error.main" }}>
            <LogoutIcon sx={{ mr: 1 }} />
            Logout
          </MenuItem>
        </Menu>

        {/* Account Details Dialog */}
        <Dialog
          open={showAccountDialog}
          onClose={() => setShowAccountDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Account Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Name
                </Typography>
                <Typography variant="body1">{userDetails?.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Email
                </Typography>
                <Typography variant="body1">{userDetails?.email}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Phone Number
                </Typography>
                <Typography variant="body1">
                  {userDetails?.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  ID Number
                </Typography>
                <Typography variant="body1">
                  {userDetails?.nationalId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  County
                </Typography>
                <Typography variant="body1">{userDetails?.county}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Sub County
                </Typography>
                <Typography variant="body1">
                  {userDetails?.subCounty}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  Ward
                </Typography>
                <Typography variant="body1">{userDetails?.ward}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAccountDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Logout Confirmation Dialog */}
        <Dialog
          open={showLogoutDialog}
          onClose={() => setShowLogoutDialog(false)}
        >
          <DialogTitle>Confirm Logout</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowLogoutDialog(false)}>Cancel</Button>
            <Button
              onClick={handleLogoutConfirm}
              color="error"
              variant="contained"
              autoFocus
            >
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
