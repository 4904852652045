import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const RegisterPopup = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    phone: "",
    gender: "",
    altPhone: "",
    nationalId: "",
    county: "",
    subCounty: "",
    ward: "",
    physicalAddress: "",
    postalAddress: "",
    plotNo: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (!open) {
      setFormData({
        firstName: "",
        secondName: "",
        phone: "",
        gender: "",
        altPhone: "",
        nationalId: "",
        county: "",
        subCounty: "",
        ward: "",
        physicalAddress: "",
        postalAddress: "",
        plotNo: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
      setError("");
      setSuccessMessage("");
      setShowPassword(false);
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setActiveStep((prev) => prev + 1);
      setError("");
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    setError("");
  };

  const validateCurrentStep = () => {
    switch (activeStep) {
      case 0:
        if (
          !formData.firstName ||
          !formData.secondName ||
          !formData.phone ||
          !formData.gender ||
          !formData.email
        ) {
          setError(
            "Please fill in all required fields in Personal Information"
          );
          return false;
        }
        break;
      case 1:
        if (!formData.county || !formData.subCounty || !formData.ward) {
          setError("Please fill in all required location fields");
          return false;
        }
        break;
      case 2:
        if (!formData.password || !formData.confirmPassword) {
          setError("Please fill in all required account fields");
          return false;
        }
        if (formData.password !== formData.confirmPassword) {
          setError("Passwords do not match");
          return false;
        }
        break;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateCurrentStep()) return;

    setError("");
    setLoading(true);

    try {
      const response = await fetch("/api/ownerships/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: `${formData.firstName} ${formData.secondName}`,
          phoneNumber: formData.phone,
          altPhoneNumber: formData.altPhone,
          gender:
            formData.gender.charAt(0).toUpperCase() + formData.gender.slice(1),
          nationalId: formData.nationalId,
          county: formData.county,
          subCounty: formData.subCounty,
          ward: formData.ward,
          physicalAddress: formData.physicalAddress,
          postalAddress: formData.postalAddress,
          email: formData.email,
          plotNo: formData.plotNo,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.message.includes("already registered")) {
          setError("This account is already registered. Please login instead.");
        } else if (data.message.includes("No ownership record found")) {
          setError(
            "No ownership record found with this phone number. Please contact admin."
          );
        } else {
          throw new Error(data.message || "Registration failed");
        }
        return;
      }

      setSuccessMessage("Registration successful! You can now login.");
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (err) {
      setError(err.message || "An error occurred during registration");
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <TextField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Second Name"
                name="secondName"
                value={formData.secondName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Box>
            <TextField
              label="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              label="Alternative Phone Number"
              name="altPhone"
              value={formData.altPhone}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
            />
            <FormControl fullWidth required>
              <InputLabel>Gender</InputLabel>
              <Select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                label="Gender"
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="National ID"
              name="nationalId"
              value={formData.nationalId}
              onChange={handleChange}
              fullWidth
              required
            />
          </Box>
        );
      case 1:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="County"
              name="county"
              value={formData.county}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              label="Sub County"
              name="subCounty"
              value={formData.subCounty}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              label="Ward"
              name="ward"
              value={formData.ward}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              label="Physical Address"
              name="physicalAddress"
              value={formData.physicalAddress}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Postal Address"
              name="postalAddress"
              value={formData.postalAddress}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Plot Number"
              name="plotNo"
              value={formData.plotNo}
              onChange={handleChange}
              fullWidth
              required
            />
          </Box>
        );
      case 2:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              required
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const steps = ["Personal Information", "Location Details", "Account Setup"];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography
          variant="h6"
          color="primary"
          fontWeight="bold"
          align="center"
          sx={{ mb: 1 }}
        >
          Create Account
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", mt: 2, mb: 4 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {renderStepContent(activeStep)}
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {successMessage && (
          <Typography color="success.main" variant="body2" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button onClick={handleBack} color="primary">
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Register"
            )}
          </Button>
        ) : (
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RegisterPopup;
