import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  LinearProgress,
  Button,
  Avatar,
  Divider,
  Badge,
  CircularProgress,
  Alert,
  Container,
} from "@mui/material";
import {
  Person,
  LocationOn,
  Phone,
  Email,
  VerifiedUser,
  Warning,
  TrendingUp,
  Search,
  Support,
  Visibility,
  ArrowForward,
  AccountBalanceWallet,
  Assignment,
  CalendarToday,
  Notifications,
  CreditCard,
  Receipt,
  PieChart,
  Update,
  Lock,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import React from "react";

// Styled components
const StyledCard = ({ children, ...props }) => (
  <motion.div whileHover={{ scale: 1.01 }}>
    <Card
      {...props}
      sx={{
        borderRadius: 1,
        boxShadow: "0 2px 6px rgba(0,0,0,0.05)",
        height: "100%",
        p: 0.5,
        ...props.sx,
      }}
    >
      {children}
    </Card>
  </motion.div>
);

// Update the chart data to show yearly trends
const yearlyData = [
  { year: "2019", amount: 150000 },
  { year: "2020", amount: 180000 },
  { year: "2021", amount: 220000 },
  { year: "2022", amount: 280000 },
  { year: "2023", amount: 320000 },
  { year: "2024", amount: 250000 },
];

export default function Dashboard() {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState({
    totalPlots: 0,
    totalArrears: 0,
    currentDue: 0,
    dueDate: "2024-03-15",
    totalSearches: 0,
    supportCases: 0,
    verificationProgress: 0,
  });
  const [recentSearches, setRecentSearches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetailsAndStats = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          navigate("/login");
          return;
        }

        const decodedToken = jwtDecode(token);
        setUserDetails({
          id: decodedToken.id,
          name: decodedToken.name || "User",
          email: decodedToken.email || "N/A",
          phoneNumber: decodedToken.phone,
          alternativePhoneNumber: decodedToken.alternativePhoneNumber,
          firstApprover: decodedToken.firstApprover,
          secondApprover: decodedToken.secondApprover,
          thirdApprover: decodedToken.thirdApprover,
          plotNo: decodedToken.plotNo,
          nationalId: decodedToken.nationalId,
          gender: decodedToken.gender,
          county: decodedToken.county,
          subCounty: decodedToken.subCounty,
          ward: decodedToken.ward,
          physicalAddress: decodedToken.physicalAddress,
          postalAddress: decodedToken.postalAddress,
        });

        // Fetch plots and searches count if user is verified
        if (
          decodedToken.firstApprover &&
          decodedToken.secondApprover &&
          decodedToken.thirdApprover
        ) {
          // Fetch plots, searches count, recent searches, and support cases in parallel
          const [
            plotsResponse,
            searchesResponse,
            recentSearchesResponse,
            supportResponse,
            paymentsResponse,
          ] = await Promise.all([
            fetch(`/api/parcellink?ownershipId=${decodedToken.id}`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            fetch(`/api/searches?applicantId=${decodedToken.id}`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            fetch(`/api/searches?applicantId=${decodedToken.id}&limit=3`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
            fetch(
              `/api/enquiries?ownershipId=${decodedToken.id}&status=false`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            ),
            fetch(`/api/billings?ownershipId=${decodedToken.id}`, {
              headers: { Authorization: `Bearer ${token}` },
            }),
          ]);

          // Process plots data
          let plotsCount = 0;
          if (plotsResponse.ok) {
            const plotsData = await plotsResponse.json();
            plotsCount = plotsData.data?.length || 0;
          }

          // Process searches data
          let searchesCount = 0;
          if (searchesResponse.ok) {
            const searchesData = await searchesResponse.json();
            searchesCount = searchesData.total || 0;
          }

          // Process support cases data
          let supportCases = 0;
          if (supportResponse.ok) {
            const supportData = await supportResponse.json();
            supportCases = supportData.total || 0;
          }

          // Process payments data
          let totalArrears = 0;
          if (paymentsResponse.ok) {
            const paymentsData = await paymentsResponse.json();
            console.log(paymentsData);

            totalArrears = paymentsData.totalArrears; // Use totalArrears from the API response
          }

          // Process recent searches
          if (recentSearchesResponse.ok) {
            const recentSearchesData = await recentSearchesResponse.json();
            setRecentSearches(
              recentSearchesData.data.map((search) => ({
                id: search.id,
                date: new Date(search.createdAt).toLocaleDateString(),
                plotNo: search.plotNumber,
                type: search.purposeOfSearch,
                status: search.status,
              }))
            );
          }

          // Update statistics with real data
          setStatistics((prev) => ({
            ...prev,
            totalPlots: plotsCount,
            totalSearches: searchesCount,
            supportCases: supportCases,
            totalArrears: totalArrears,
            verificationProgress: getVerificationStatus().progress,
          }));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        localStorage.removeItem("authToken");
        navigate("/login");
      }
    };

    fetchUserDetailsAndStats();
  }, [navigate]);

  const getVerificationStatus = () => {
    if (!userDetails)
      return {
        status: "Pending",
        progress: 0,
        message: "Your account is awaiting initial verification",
        color: "warning.main", // Default color
      };

    const { firstApprover, secondApprover, thirdApprover } = userDetails;

    if (!firstApprover && !secondApprover && !thirdApprover) {
      return {
        status: "Pending First Approval",
        progress: 0,
        message: "Your account is awaiting initial verification",
        color: "warning.main", // Orange for pending
      };
    }

    if (firstApprover && !secondApprover && !thirdApprover) {
      return {
        status: "Second Stage",
        progress: 33.33,
        message: "Your account is in second stage of verification",
        color: "#FF9800", // Orange for in-progress
      };
    }

    if (firstApprover && secondApprover && !thirdApprover) {
      return {
        status: "Final Stage",
        progress: 66.66,
        message: "Your account is in final stage of verification",
        color: "#2196F3", // Blue for near completion
      };
    }

    if (firstApprover && secondApprover && thirdApprover) {
      return {
        status: "Verified",
        progress: 100,
        message: "Your account is fully verified",
        color: "#4CAF50", // Green for verified
      };
    }

    return {
      status: "Pending",
      progress: 0,
      message: "Verification status unknown",
      color: "warning.main",
    };
  };

  const verificationStatus = getVerificationStatus();
  const isFullyVerified =
    userDetails?.firstApprover &&
    userDetails?.secondApprover &&
    userDetails?.thirdApprover;

  const recentTransactions = [
    {
      id: 1,
      date: "2024-02-10",
      type: "Ground Rent",
      amount: 12000,
      status: "Paid",
    },
    {
      id: 2,
      date: "2024-01-25",
      type: "Search Fee",
      amount: 1000,
      status: "Paid",
    },
    {
      id: 3,
      date: "2024-01-15",
      type: "Land Rate",
      amount: 5000,
      status: "Pending",
    },
  ];

  // Add notification state
  const [notifications] = useState([
    {
      id: 1,
      type: "payment",
      message: "Ground rent payment due in 5 days",
      urgent: true,
    },
  ]);

  // Add click handlers
  const handleNavigate = (path) => {
    navigate(path);
  };

  // First create an array of quick actions with their paths and icons
  const quickActions = [
    { name: "Payments", path: "/payments", icon: <CreditCard /> },
    { name: "Plots", path: "/plots", icon: <AccountBalanceWallet /> },
    { name: "Request Support", path: "/support", icon: <Support /> },
    { name: "Change Password", path: "/settings", icon: <Lock /> },
  ];

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth="lg">
      <Box sx={{ bgcolor: "#F5F7FA", minHeight: "100vh", pt: 3 }}>
        {/* Header Section - Always visible */}
        <Box sx={{ maxWidth: 1200, mx: "auto", px: 3, py: 4 }}>
          {/* Personal Info Card */}
          <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: "white" }}>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
              <Avatar
                sx={{
                  width: 52,
                  height: 52,
                  bgcolor: "primary.main",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                  border: "2px solid #fff",
                  fontSize: "1.5rem",
                  background: "linear-gradient(135deg, #0033A0, #2196F3)",
                }}
              >
                {userDetails?.name?.charAt(0) || "U"}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h5" fontWeight={500}>
                  Welcome back, {userDetails?.name || "User"}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    ID Number: {userDetails?.nationalId || "N/A"}
                  </Typography>
                </Box>
              </Box>

              {/* Verification Status */}
              <Box
                sx={{
                  display: "flex",
                  gap: 1.5,
                  alignItems: "center",
                  background: isFullyVerified
                    ? "linear-gradient(135deg, #f8fdfb, #edf7f0)"
                    : `linear-gradient(135deg, ${verificationStatus.color}10, ${verificationStatus.color}05)`,
                  p: "12px",
                  borderRadius: "8px",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                }}
              >
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      fontWeight: 500,
                      color: verificationStatus.color,
                    }}
                  >
                    {isFullyVerified ? (
                      <>
                        <VerifiedUser sx={{ fontSize: 16 }} />
                        Account Verified
                      </>
                    ) : (
                      <>
                        <Update sx={{ fontSize: 16 }} />
                        {verificationStatus.status}
                      </>
                    )}
                  </Typography>
                  {!isFullyVerified && (
                    <>
                      <Typography
                        variant="caption"
                        sx={{
                          display: "block",
                          mb: 0.5,
                          color: "text.secondary",
                        }}
                      >
                        {verificationStatus.message}
                      </Typography>
                      <Box sx={{ width: 120, mt: 0.5 }}>
                        <LinearProgress
                          variant="determinate"
                          value={verificationStatus.progress}
                          sx={{
                            height: 4,
                            borderRadius: 2,
                            bgcolor: "rgba(0,0,0,0.1)",
                            "& .MuiLinearProgress-bar": {
                              bgcolor: verificationStatus.color,
                              transition:
                                "transform 0.4s ease, background-color 0.4s ease",
                            },
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              borderRadius: 2,
                              animation:
                                verificationStatus.progress < 100
                                  ? "pulse 2s infinite"
                                  : "none",
                              backgroundColor: verificationStatus.color,
                              opacity: 0.1,
                            },
                            "@keyframes pulse": {
                              "0%": {
                                opacity: 0.1,
                              },
                              "50%": {
                                opacity: 0.2,
                              },
                              "100%": {
                                opacity: 0.1,
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>

            {!isFullyVerified && (
              <Alert
                severity="warning"
                sx={{ mt: 2 }}
                action={
                  <Button color="inherit" size="small">
                    Learn More
                  </Button>
                }
              >
                Your account is pending verification. Some features are limited
                until verification is complete.
              </Alert>
            )}
          </Paper>

          {/* Main Dashboard Content */}
          {isFullyVerified ? (
            <>
              {/* Statistics Cards */}
              <Grid container spacing={2} sx={{ mb: 3 }}>
                {/* Total Plots */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledCard
                    sx={{
                      background:
                        "linear-gradient(135deg, #2196F3 0%, #1976D2 100%)",
                      color: "white",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                    onClick={() => handleNavigate("/plots")}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <AccountBalanceWallet sx={{ fontSize: 20 }} />
                        <TrendingUp sx={{ fontSize: 16 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mb: 0.5 }}>
                        {statistics.totalPlots}
                      </Typography>
                      <Typography fontSize={10}>Total Plots</Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Current Due */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledCard
                    sx={{
                      background:
                        "linear-gradient(135deg, #FF9800 0%, #F57C00 100%)",
                      color: "white",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Assignment sx={{ fontSize: 20 }} />
                        <CalendarToday sx={{ fontSize: 16 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mb: 0.5 }}>
                        KES {statistics.currentDue}
                      </Typography>
                      <Typography fontSize={10}>
                        Current Due • {statistics.dueDate}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Total Arrears */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledCard
                    sx={{
                      background:
                        "linear-gradient(135deg, #f44336 0%, #d32f2f 100%)",
                      color: "white",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Receipt sx={{ fontSize: 20 }} />
                        <Warning sx={{ fontSize: 16 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mb: 0.5 }}>
                        KES {statistics.totalArrears.toLocaleString()}
                      </Typography>
                      <Typography fontSize={10}>Total Arrears</Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Total Searches */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledCard
                    sx={{
                      background:
                        "linear-gradient(135deg, #4CAF50 0%, #388E3C 100%)",
                      color: "white",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                    onClick={() => handleNavigate("/search")}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Search sx={{ fontSize: 20 }} />
                        <TrendingUp sx={{ fontSize: 16 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mb: 0.5 }}>
                        {statistics.totalSearches}
                      </Typography>
                      <Typography fontSize={10}>Total Searches</Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Support Cases */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledCard
                    sx={{
                      background:
                        "linear-gradient(135deg, #9C27B0 0%, #7B1FA2 100%)",
                      color: "white",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                    onClick={() => handleNavigate("/support")}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Support sx={{ fontSize: 20 }} />
                        <TrendingUp sx={{ fontSize: 16 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mb: 0.5 }}>
                        {statistics.supportCases}
                      </Typography>
                      <Typography fontSize={10}>Support Cases</Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>

              {/* Charts and Tables */}
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <TrendingUp />
                          Payment Trends
                        </Typography>
                        <Button
                          size="small"
                          startIcon={<CalendarToday />}
                          sx={{ textTransform: "none" }}
                        >
                          Last 6 Years
                        </Button>
                      </Box>
                      <Box sx={{ height: 240 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart data={yearlyData}>
                            <defs>
                              <linearGradient
                                id="colorAmount"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#2196F3"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#2196F3"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <CartesianGrid
                              strokeDasharray="3 3"
                              stroke="#eee"
                            />
                            <XAxis
                              dataKey="year"
                              tickFormatter={(value) => `'${value.slice(-2)}`}
                            />
                            <YAxis
                              tickFormatter={(value) =>
                                `${value.toLocaleString()}`
                              }
                            />
                            <RechartsTooltip
                              contentStyle={{
                                background: "#fff",
                                border: "none",
                                borderRadius: 8,
                                boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
                              }}
                              formatter={(value) => [
                                `KES ${value.toLocaleString()}`,
                                "Amount",
                              ]}
                              labelFormatter={(label) => `Year ${label}`}
                            />
                            <Area
                              type="monotone"
                              dataKey="amount"
                              stroke="#2196F3"
                              fillOpacity={1}
                              fill="url(#colorAmount)"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>

                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 3,
                        }}
                      >
                        <Typography variant="h6">
                          Recent Transactions
                        </Typography>
                        <Button endIcon={<ArrowForward />} size="small">
                          View All
                        </Button>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell align="right">Amount</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {recentTransactions.map((transaction) => (
                              <TableRow key={transaction.id} hover>
                                <TableCell>{transaction.date}</TableCell>
                                <TableCell>{transaction.type}</TableCell>
                                <TableCell align="right">
                                  KES {transaction.amount}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label={transaction.status}
                                    color={
                                      transaction.status === "Paid"
                                        ? "success"
                                        : "warning"
                                    }
                                    size="small"
                                    sx={{ borderRadius: 1 }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </StyledCard>
                </Grid>

                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 3,
                        }}
                      >
                        <Typography variant="h6">Recent Searches</Typography>
                        <Button
                          endIcon={<ArrowForward />}
                          size="small"
                          onClick={() => handleNavigate("/search")}
                        >
                          View All
                        </Button>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Plot Number</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell align="right">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {recentSearches.map((search) => (
                              <TableRow key={search.id} hover>
                                <TableCell>{search.date}</TableCell>
                                <TableCell>{search.plotNo}</TableCell>
                                <TableCell>{search.type}</TableCell>
                                <TableCell align="right">
                                  <Tooltip title="View Details">
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={() =>
                                        navigate(`/searches/${search.id}`)
                                      }
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            </>
          ) : (
            <Paper
              elevation={0}
              sx={{
                p: 4,
                textAlign: "center",
                bgcolor: "#fff8e1",
                borderRadius: 2,
                mb: 3,
              }}
            >
              <Typography variant="h6" gutterBottom color="warning.dark">
                Complete Your Account Verification
              </Typography>
              <Typography color="text.secondary" sx={{ mb: 2 }}>
                Once your account is verified, you'll have access to:
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ maxWidth: 600, margin: "0 auto" }}
              >
                <Grid item xs={6}>
                  <Box sx={{ p: 2, bgcolor: "white", borderRadius: 1 }}>
                    <AccountBalanceWallet
                      sx={{ color: "primary.main", mb: 1 }}
                    />
                    <Typography variant="body2">Plot Information</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ p: 2, bgcolor: "white", borderRadius: 1 }}>
                    <Receipt sx={{ color: "primary.main", mb: 1 }} />
                    <Typography variant="body2">Payment History</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ p: 2, bgcolor: "white", borderRadius: 1 }}>
                    <Search sx={{ color: "primary.main", mb: 1 }} />
                    <Typography variant="body2">Search Services</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ p: 2, bgcolor: "white", borderRadius: 1 }}>
                    <Support sx={{ color: "primary.main", mb: 1 }} />
                    <Typography variant="body2">Support Features</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          )}

          {/* Quick Actions - Always visible and now at the bottom */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Quick Actions
            </Typography>
            <Grid container spacing={2}>
              {quickActions.map((action) => (
                <Grid item xs={6} sm={3} key={action.name}>
                  <StyledCard
                    sx={{
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                    onClick={() => handleNavigate(action.path)}
                  >
                    <CardContent
                      sx={{
                        textAlign: "center",
                        py: 2,
                        "&:last-child": { pb: 2 },
                      }}
                    >
                      <IconButton
                        sx={{
                          bgcolor: "primary.light",
                          mb: 1,
                          "&:hover": { bgcolor: "primary.main" },
                        }}
                      >
                        {React.cloneElement(action.icon, {
                          sx: { color: "white" },
                        })}
                      </IconButton>
                      <Typography variant="body2">{action.name}</Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
