import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    success: {
      main: "#A0D7E5", // Cyan as primary color
      light: "#B3DFE9", // 20% lighter than main
      dark: "#80ADB7", // 20% darker than main
    },
    secondary: {
      main: "#F5A721", // Yellow as secondary color
      light: "#F7B94D", // 20% lighter than main
      dark: "#C4851A", // 20% darker than main
    },
    primary: {
      main: "#4C912F", // Green as success color
      light: "#70A758", // 20% lighter than main
      dark: "#3D7426", // 20% darker than main
    },
  },
});

export default theme;
