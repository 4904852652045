import React, { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";
import EnquiryDialog from "../components/HelpDesk/EnquiryDialog";
import { Add } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";

export default function CSEnquiries(props) {
  const [enquiries, setEnquiries] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [column, setColumn] = useState("title");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [resolutionDialogOpen, setResolutionDialogOpen] = useState(false);
  const [resolutionText, setResolutionText] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No authentication token found");
        }

        const decodedToken = jwtDecode(token);
        setUserDetails({
          id: decodedToken.id,
          name: decodedToken.name,
          phone: decodedToken.phoneNumber,
          email: decodedToken.email,
        });

        fetchEnquiries();
      } catch (error) {
        console.error("Error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    if (userDetails?.id) {
      fetchEnquiries();
    }
  }, [page, rowsPerPage, column, searchValue, refresh, userDetails?.id]);

  const fetchEnquiries = () => {
    if (!userDetails?.id) return;

    setLoading(true);
    const url = `/api/enquiries?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}${
      searchValue ? `&${column}=${searchValue}` : ""
    }&ownershipId=${userDetails.id}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject("Error fetching enquiries")
      )
      .then((data) => {
        setLoading(false);
        setEnquiries(data?.data);
        setCount(data?.total);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPage(0);
  };

  const handleStatusUpdate = () => {
    setUpdating(true);
    fetch(`/api/enquiries/${selectedEnquiry.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: true,
        resolution: resolutionText,
        userId: props.user.id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setEnquiries((prevEnquiries) =>
            prevEnquiries.map((enq) =>
              enq.ID === selectedEnquiry.ID
                ? {
                    ...enq,
                    status: true,
                    resolution: resolutionText,
                    userId: props.user.id,
                  }
                : enq
            )
          );
          setResolutionDialogOpen(false);
          setSelectedEnquiry(null);
        } else throw new Error("Error updating enquiry status");
      })
      .catch((e) => console.error("Failed to update enquiry status:", e))
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleRowClick = (enquiry) => {
    setSelectedEnquiry(enquiry);
  };

  const handleCloseDetailsDialog = () => {
    setSelectedEnquiry(null);
  };

  const handleOpenResolutionDialog = () => {
    setResolutionDialogOpen(true);
  };

  const handleCloseResolutionDialog = () => {
    setResolutionDialogOpen(false);
    setResolutionText("");
  };

  return (
    <Box
      p={2}
      component={Card}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        margin: "3rem 1rem",
      }}
    >
      <Box display="flex" gap={2} alignItems="center" mb={2}>
        <Box flexGrow={1}>
          <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>
            My Support Issues
          </Typography>
        </Box>
        <FormControl>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
            sx={{ minWidth: "150px" }}
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="category">Category</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <Add /> Add Issue
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SN</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enquiries.length > 0 ? (
                enquiries.map((enquiry, index) => (
                  <TableRow
                    key={enquiry.ID}
                    hover
                    onClick={() => handleRowClick(enquiry)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{enquiry.title}</TableCell>
                    <TableCell>{enquiry.name}</TableCell>
                    <TableCell>{enquiry.phone}</TableCell>
                    <TableCell>{enquiry.category}</TableCell>
                    <TableCell>
                      {dayjs(enquiry.createdAt).format("YYYY-MM-DD HH:mm")}
                    </TableCell>{" "}
                    <TableCell>
                      <Chip
                        label={enquiry.status ? "Resolved" : "Unresolved"}
                        color={enquiry.status ? "success" : "warning"}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No enquiries found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) =>
          setRowsPerPage(parseInt(event.target.value, 10))
        }
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <EnquiryDialog
        open={open}
        onClose={() => setOpen(false)}
        refresh={refresh}
        setRefresh={setRefresh}
        userDetails={userDetails}
      />

      <Dialog
        open={resolutionDialogOpen}
        onClose={handleCloseResolutionDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="title">Provide Resolution</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            sx={{ mt: 1 }}
            rows={4}
            variant="outlined"
            label="Resolution"
            value={resolutionText}
            onChange={(e) => setResolutionText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResolutionDialog} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStatusUpdate}
            disabled={updating}
          >
            {updating ? "Submiting..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
