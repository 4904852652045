import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Eye as EyeIcon } from "@phosphor-icons/react";
import { EyeSlash as EyeSlashIcon } from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import lg_img from "../assets/imgs/lg.png";
import ForgotPassword from "./ForgotPassword";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const schema = zod.object({
  Email: zod.string().min(1, { message: "Email is required" }).email(),
  Password: zod.string().min(1, { message: "Password is required" }),
});

const defaultValues = { Email: "", Password: "" };

export default function LoginPage(props) {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [severity, setSeverity] = React.useState("error");
  const [isError, setIsError] = React.useState("");
  const [showing, setShowing] = React.useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [userId, setUserId] = useState(null);
  const [timer, setTimer] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    let interval;
    if (showVerification && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showVerification, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const onSubmit = async (data) => {
    setIsError("");
    setSeverity("error");
    setIsPending(true);

    try {
      const response = await fetch("/api/ownerships/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          phone: data.Email, // Using Email field for phone number
          password: data.Password,
        }),
      });

      const body = await response.json();
      setIsPending(false);

      if (response.ok && body.requireVerification) {
        setUserId(body.userId);
        setShowVerification(true);
        setTimer(300);
        setSeverity("info");
        setIsError(body.message);
      } else if (!response.ok) {
        switch (response.status) {
          case 404:
            setIsError("Phone number not found. Please register first.");
            break;
          case 401:
            setIsError("Incorrect password. Please try again.");
            break;
          default:
            setIsError(body?.message ?? "Login failed");
        }
      }
    } catch (error) {
      setIsPending(false);
      setIsError(
        "Could not connect to the server. Please check your internet connection."
      );
    }
  };

  const handleVerificationSubmit = async () => {
    if (!verificationCode) {
      setIsError("Please enter verification code");
      return;
    }

    setIsPending(true);
    try {
      const response = await fetch("/api/ownerships/verify-2fa", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          code: verificationCode,
        }),
      });

      const body = await response.json();
      setIsPending(false);

      if (response.ok) {
        setSeverity("success");
        localStorage.setItem("authToken", body.token);
        localStorage.setItem("userId", body.public.id);
        localStorage.setItem("ownershipId", body.public.ownershipId);
        localStorage.setItem("userName", body.public.name);
        setIsError(body?.message ?? "Login successful");
        setTimeout(() => {
          navigate("/home");
        }, 500);
      } else {
        setIsError(body.message);
      }
    } catch (error) {
      setIsPending(false);
      setIsError(
        "Could not connect to the server. Please check your internet connection."
      );
    }
  };

  return (
    <Box
      sx={{
        display: { xs: "flex", lg: "grid" },
        flexDirection: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "100vh",
        background:
          "radial-gradient(50% 50% at 50% 50%,#6ec1e4  0%, #0F67B9 100%)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          p: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: "450px",
            width: "100%",
            p: 4,
            borderRadius: "16px",
            boxShadow: "0 20px 50px #00000050",
          }}
        >
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography
                sx={{ textAlign: "center", color: "#0033A0" }}
                variant="h4"
              >
                Sign in
              </Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Controller
                  control={control}
                  name="Email"
                  render={({ field }) => (
                    <FormControl error={Boolean(errors.Email)}>
                      <InputLabel>Email address</InputLabel>
                      <OutlinedInput
                        {...field}
                        label="Email address"
                        type="email"
                      />
                      {errors.Email ? (
                        <FormHelperText>{errors.Email.message}</FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="Password"
                  render={({ field }) => (
                    <FormControl error={Boolean(errors.Password)}>
                      <InputLabel>Password</InputLabel>
                      <OutlinedInput
                        {...field}
                        endAdornment={
                          showPassword ? (
                            <EyeIcon
                              cursor="pointer"
                              fontSize="var(--icon-fontSize-md)"
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <EyeSlashIcon
                              cursor="pointer"
                              fontSize="var(--icon-fontSize-md)"
                              onClick={() => setShowPassword(true)}
                            />
                          )
                        }
                        label="Password"
                        type={showPassword ? "text" : "password"}
                      />
                      {errors.Password ? (
                        <FormHelperText>
                          {errors.Password.message}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
                <div>
                  <Link
                    component={RouterLink}
                    onClick={() => {
                      setShowing(true);
                    }}
                    variant="subtitle2"
                    sx={{ textAlign: "center", color: "#0033A0" }}
                  >
                    Forgot password?
                  </Link>
                </div>
                {isError && <Alert severity={severity}>{isError}</Alert>}
                <Button
                  disabled={isPending}
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={isPending ? <CircularProgress size={24} /> : null}
                >
                  {isPending ? "Signing in..." : "Sign in"}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Card>
      </Box>
      <Box
        sx={{
          alignItems: "center",

          color: "var(--mui-palette-common-white)",
          display: { xs: "none", lg: "grid" },
          gridTemplateRows: "auto 1fr",
          justifyContent: "center",
          p: 3,
          minHeight: "100%",
        }}
      >
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography
              color="inherit"
              sx={{
                fontSize: "xx-large",
                color: "orange",
                lineHeight: "44px",
                textAlign: "center",
              }}
              variant="h1"
            >
              Welcome to <br></br>
              <br></br>
              <Box component="span" sx={{ color: "white", fontWeight: "900" }}>
                Homabay Land Information Management System
              </Box>{" "}
              (Public Portal)
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              alt="Widgets"
              src={lg_img}
              sx={{ height: "auto", width: "100%", maxWidth: "250px" }}
            />
          </Box>
        </Stack>
      </Box>
      <ForgotPassword open={showing} showForgotPassword={setShowing} />
      <Dialog
        open={showVerification}
        onClose={() => setShowVerification(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center", color: "#0033A0" }}>
          Verify Login
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography>
              Please enter the verification code sent to your email.
            </Typography>
            <Typography color="primary" variant="subtitle2">
              Time remaining: {formatTime(timer)}
            </Typography>
            <TextField
              label="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              required
            />
            {isError && (
              <Alert
                severity={severity}
                sx={{
                  "& .MuiAlert-message": {
                    whiteSpace: "pre-line",
                    fontWeight: severity === "error" ? 500 : 400,
                  },
                }}
              >
                {isError}
              </Alert>
            )}
            <Button
              onClick={handleVerificationSubmit}
              variant="contained"
              color="primary"
              disabled={isPending || timer === 0}
              fullWidth
            >
              {isPending ? <CircularProgress size={24} /> : "Verify"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
