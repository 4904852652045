import React from "react";
import { Typography, Box, Container, Tooltip } from "@mui/material";
import Hero from "../../assets/imgs/landing.jpg";
import Pop from "../../assets/imgs/pop.png";
import { Map, Payment, SearchSharp } from "@mui/icons-material";
export default function HeroSection() {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)", // Adjusted height for header offset
        position: "relative",
        backgroundImage: `url(${Hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: { xs: 2, md: 4 },
              p: { xs: 2, md: 4 },
              borderRadius: "12px",
            }}
          >
            <Typography
              variant="h3"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "#ffffff",
                flex: "1 1 auto",
                maxWidth: { xs: "100%", md: "50%" },
              }}
            >
              Welcome to Homabay LIMS Public Portal
            </Typography>
            <Box
              component="img"
              src={Pop}
              alt="Homabay LIMS Public Portal"
              sx={{
                width: { xs: "100%", sm: "75%", md: "550px" }, // Responsive image width
                height: "auto",
                borderRadius: "12px",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.4)", // Enhanced shadow for depth
                objectFit: "cover", // Ensures the image covers the box properly
              }}
            />
          </Box>
        </Container>
      </Box>
      <Container>
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-12%", md: "-12%" }, // Unified bottom position
            left: "50%",
            transform: "translateX(-50%)",
            width: { xs: "90%", md: "60%" },
            minHeight: "80px", // Unified height
            backgroundColor: "rgba(0, 48, 100, 0.9)",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 2, // Unified padding
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "white",
              fontWeight: "bold",
              paddingBottom: "0.5rem", // Reduced padding
            }}
          >
            Our Main Services
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: { xs: 3, md: 12 }, // Reduced gap on mobile but kept horizontal
              justifyContent: "center",
              flexDirection: "row", // Always horizontal
            }}
          >
            {[
              {
                icon: <Map fontSize="large" />,
                label: "Plots",
                description: "List of plots owned by individuals",
              },
              {
                icon: <Payment fontSize="large" />,
                label: "Payments",
                description: "Information on payments made on plots",
              },
              {
                icon: <SearchSharp fontSize="large" />,
                label: "Plots Search",
                description: "Search for Plots by owner or location",
              },
            ].map((service, index) => (
              <Tooltip key={index} title={service.description} arrow>
                <Box
                  sx={{
                    textAlign: "center",
                    color: "#FFB74D", // Changed to a more contrasting orange
                    cursor: "pointer",
                  }}
                >
                  {service.icon}
                  <Typography sx={{ fontWeight: "800" }}>
                    {service.label}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
