import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Upload } from "@mui/icons-material";

const steps = ["FAQs", "Search details", "Payment", "Confirmation"];

const FAQSection = () => (
  <Box>
    <Typography variant="subtitle1" gutterBottom>
      Frequently Asked Questions about Parcel Search
    </Typography>

    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          What is an Official Search?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          An official search is a formal inquiry into the records of a
          particular parcel of land maintained by the Land Registry. It provides
          current information about ownership, encumbrances, and other
          registered interests affecting the property.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          What information do I need to conduct a search?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          You will need:
          <ul>
            <li>The correct parcel number or title number</li>
            <li>Your identification details (ID/Passport)</li>
            <li>Purpose of the search</li>
            <li>Payment of prescribed fees</li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          What will the search reveal?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          The search can reveal:
          <ul>
            <li>Current ownership details</li>
            <li>Size and location of the parcel</li>
            <li>
              Any registered encumbrances (charges, cautions, restrictions)
            </li>
            <li>Lease details (if leasehold)</li>
            <li>Any pending transactions</li>
          </ul>
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          How long is the search valid?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          An official search certificate is typically valid for 90 days from the
          date of issue. However, it represents the position of the register at
          the time of search only.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Important Notes:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <ul>
            <li>Ensure the parcel number is correct to avoid delays</li>
            <li>The search fee is non-refundable</li>
            <li>Processing time may vary depending on the registry workload</li>
            <li>
              Additional documents may be required for certain types of searches
            </li>
          </ul>
        </Typography>
      </Box>
    </Box>
  </Box>
);

const NewApplication = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [applicantId, setApplicantId] = useState(null);
  const navigate = useNavigate();
  const [paymentEvidence, setPaymentEvidence] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        showSnackbar("Please login to submit a search application", "error");
        navigate("/login");
        return;
      }

      const decodedToken = jwtDecode(token);
      setApplicantId(decodedToken.id);

      // Set user details from token including phone
      setFormData((prev) => ({
        ...prev,
        applicantName: decodedToken.name || "",
        idNumber: decodedToken.nationalId || "",
        applicantPhone: decodedToken.phoneNumber || "",
        applicantId: decodedToken.id,
      }));
    } catch (error) {
      console.error("Token decode error:", error);
      showSnackbar("Authentication error. Please login again.", "error");
      navigate("/login");
    }
  }, [navigate]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [formData, setFormData] = useState({
    plotNumber: "",
    applicantName: "",
    applicantPhone: "",
    idNumber: "",
    typeOfSearch: "Parcel search",
    purposeOfSearch: "",
    searchScope: {
      property: true,
      proprietorship: true,
      encumbrance: true,
    },
    applicantId: null,
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleNext = () => {
    if (activeStep === 1 && !validateSearchDetails()) {
      return;
    }
    if (activeStep === 2 && !validatePayment()) {
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const validateSearchDetails = () => {
    const requiredFields = [
      "plotNumber",
      "applicantName",
      "applicantPhone",
      "idNumber",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      showSnackbar("Please fill in all required fields", "error");
      return false;
    }
    return true;
  };

  const validatePayment = () => {
    if (!paymentEvidence) {
      showSnackbar("Please upload payment evidence", "error");
      return false;
    }
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPaymentEvidence(file);
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      if (!token || !applicantId) {
        throw new Error("Authentication required");
      }

      // First, upload the payment evidence file
      if (paymentEvidence) {
        const uploadFormData = new FormData();
        uploadFormData.append("paymentEvidence", paymentEvidence);

        const uploadResponse = await fetch("/api/searches/payment-evidence", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: uploadFormData,
        });

        if (!uploadResponse.ok) {
          const errorText = await uploadResponse.text();
          try {
            const errorData = JSON.parse(errorText);
            throw new Error(
              errorData.error || "Failed to upload payment evidence"
            );
          } catch (e) {
            console.error("Upload error response:", errorText);
            throw new Error("Failed to upload payment evidence");
          }
        }

        let filename;
        try {
          const uploadData = await uploadResponse.json();
          filename = uploadData.filename;
        } catch (e) {
          console.error("Error parsing upload response:", e);
          throw new Error("Invalid response from server");
        }

        // Now submit the search application with the filename
        const searchData = {
          plotNumber: formData.plotNumber,
          applicantName: formData.applicantName,
          applicantPhone: formData.applicantPhone,
          idNumber: formData.idNumber,
          typeOfSearch: formData.typeOfSearch,
          purposeOfSearch: formData.purposeOfSearch,
          searchScope: formData.searchScope,
          status: "Ongoing",
          reference: `SEARCH/${Date.now()}`,
          applicantId: applicantId,
          paymentEvidence: filename,
        };

        const searchResponse = await fetch("/api/searches", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(searchData),
        });

        if (!searchResponse.ok) {
          const errorText = await searchResponse.text();
          try {
            const errorData = JSON.parse(errorText);
            throw new Error(errorData.error || "Failed to submit application");
          } catch (e) {
            console.error("Search error response:", errorText);
            throw new Error("Failed to submit application");
          }
        }

        showSnackbar("Search application submitted successfully");
        navigate("/search");
      }
    } catch (error) {
      console.error("Submit error:", error);
      showSnackbar(error.message || "Failed to submit application", "error");
      if (error.message === "Authentication required") {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const renderSearchDetails = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Parcel Number"
          placeholder="Enter title number or parcel number"
          value={formData.plotNumber}
          onChange={(e) =>
            setFormData({ ...formData, plotNumber: e.target.value })
          }
          helperText="Hint: Ensure to use the correct parcel number"
          error={!formData.plotNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Purpose of search"
          placeholder="Purpose of search"
          multiline
          value={formData.purposeOfSearch}
          onChange={(e) =>
            setFormData({ ...formData, purposeOfSearch: e.target.value })
          }
          error={!formData.purposeOfSearch}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          label="Applicant Name"
          value={formData.applicantName}
          InputProps={{
            readOnly: true,
          }}
          helperText="Auto-filled from your profile"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          label="Applicant Phone Number"
          value={formData.applicantPhone}
          InputProps={{
            readOnly: true,
          }}
          helperText="Auto-filled from your profile"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          label="ID Number"
          value={formData.idNumber}
          InputProps={{
            readOnly: true,
          }}
          helperText="Auto-filled from your profile"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Scope of search
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.searchScope.property}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchScope: {
                    ...formData.searchScope,
                    property: e.target.checked,
                  },
                })
              }
            />
          }
          label="Particulars noted on the Property section"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.searchScope.proprietorship}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchScope: {
                    ...formData.searchScope,
                    proprietorship: e.target.checked,
                  },
                })
              }
            />
          }
          label="Proprietorship section"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.searchScope.encumbrance}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchScope: {
                    ...formData.searchScope,
                    encumbrance: e.target.checked,
                  },
                })
              }
            />
          }
          label="Encumbrance section"
        />
      </Grid>
    </Grid>
  );

  const renderPayment = () => (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment Information
      </Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Search Fee: KSh 1,000
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Please upload a photo/scan of your payment receipt to proceed
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <input
          accept="image/*,application/pdf"
          style={{ display: "none" }}
          id="payment-evidence"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="payment-evidence">
          <Button
            variant="outlined"
            component="span"
            startIcon={<Upload />}
            fullWidth
          >
            Upload Payment Evidence
          </Button>
        </label>
      </Box>

      {previewUrl && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <img
            src={previewUrl}
            alt="Payment preview"
            style={{ maxWidth: "100%", maxHeight: 200, objectFit: "contain" }}
          />
        </Box>
      )}
    </Box>
  );

  const renderConfirmation = () => (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        Confirmation
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                No.
              </TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Parcel
              </TableCell>
              <TableCell>{formData.plotNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Applicant:
              </TableCell>
              <TableCell>
                {formData.applicantName}, ID NO. {formData.idNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Type of search:
              </TableCell>
              <TableCell>{formData.typeOfSearch}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Purpose of search:
              </TableCell>
              <TableCell>{formData.purposeOfSearch}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: "bold" }}>
                Scope of search:
              </TableCell>
              <TableCell>
                {[
                  formData.searchScope.property &&
                    "Particulars noted on the Property section",
                  formData.searchScope.proprietorship &&
                    "Proprietorship section",
                  formData.searchScope.encumbrance && "Encumbrance section",
                ]
                  .filter(Boolean)
                  .join(" / ")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <FAQSection />;
      case 1:
        return renderSearchDetails();
      case 2:
        return renderPayment();
      case 3:
        return renderConfirmation();
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Search: New Application
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
        Hint: Ensure to use the correct title number or parcel number
      </Typography>

      <Card sx={{ p: 3 }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
        >
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0 || loading}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          ) : (
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              Next
            </Button>
          )}
        </Box>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewApplication;
