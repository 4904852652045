import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
} from "@mui/material";
import Grid2 from "@mui/material/Grid2";

const newsArticles = [
  {
    title: "County Trains SACCO Officials on Inclusion Grant",
    description: "The county's department of agriculture and lives...",
    fullContent:
      "The county's department of agriculture and livestock launched a week-long training program for SACCO officials, focusing on developing proposals for the inclusion grant.",
  },
  {
    title: "Deputy Governor Urges Farmers to Tap into Opportunities",
    description: "The County Government of Homa Bay, through ...",
    fullContent:
      "The County Government of Homa Bay, through its department of Agriculture in collaboration with the department of Cooperatives, organized a consultative forum to address key issues and opportunities within the coffee sector.",
  },
  {
    title: "County Marks World Cleanup Day in Rangwe",
    description: "The County Government of Homa Bay, through ...",
    fullContent:
      "The County Government of Homa Bay, through the Department of Water, Irrigation, Sanitation, Environment, Energy, and Climate Change, successfully commemorated World Cleanup Day 2024 with a major cleanup exercise in Rangwe Town on 20th September 2024.",
  },
];

const NewsSection = () => {
  const [open, setOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle(null);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ padding: 2, margin: "6rem 0 2rem 0" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: "#061e06",
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Latest and Events News
        </Typography>
        <Divider sx={{ marginBottom: "2rem" }} />
        {newsArticles.length === 0 ? (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#555", fontWeight: 500 }}
          >
            No news to display.
          </Typography>
        ) : (
          <Grid2 container spacing={3}>
            {newsArticles.map((article, index) => (
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <Card sx={{ borderRadius: "8px", boxShadow: 3 }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        color: "#061e06",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {article.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "#555", marginBottom: "1rem" }}
                    >
                      {article.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        borderColor: "#F5A721",
                        color: "#F5A721",
                        "&:hover": {
                          backgroundColor: "#F5A721",
                          color: "#fff",
                          borderColor: "#F5A721",
                        },
                      }}
                      onClick={() => handleOpen(article)}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        )}
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>{selectedArticle?.title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ color: "#555" }}>
              {selectedArticle?.fullContent}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                color: "#F5A721",
                "&:hover": { backgroundColor: "#F5A721", color: "#fff" },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default NewsSection;
